import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { LoadingComponent } from './loading/loading.component';
import { LoadingService } from './loading.service';

@NgModule({
    imports: [
        CommonModule,
        NgOptimizedImage
    ],
    declarations: [
        LoadingComponent
    ],
    providers: [
        LoadingService
    ],
    exports: [
        LoadingComponent
    ]
})
export class LoadingModule { }

import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, switchMap} from "rxjs/operators";
import {RedirectionService} from "./redirection.service";

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

    constructor(private redirectionService: RedirectionService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {
        req = req.clone({
            withCredentials: true
        });

        return next.handle(req).pipe(
            catchError((err: any) => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    return this.redirectionService.GetRedirectionUrl().pipe(
                        switchMap((url: string) => {
                            window.location.href = url;
                            return throwError(() => err);
                        }),
                        catchError(err => {
                            return throwError(() => err);
                        })
                    );
                }
                return throwError(() => err);
            })
        );
    }
}

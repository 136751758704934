import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MarkerService } from '../../../../shared/services/marker.service';
import { MapLayer } from '../../../../shared/contract/Enum/MapLayer';
import { IconFeature } from '../../../../shared/contract/Module/DataMode';
import { ResidentialRealEstateResponse } from '../../../../shared/contract/ResidentialRealEstate/ResidentialRealEstateResponse';
import { BuildingLandResponse } from '../../../../shared/contract/BuildingLand/BuildingLandResponse';
import { SpatialResponse } from '../../../../shared/contract/SpatialAnalysis/SpatialResponse';
import { MenuService } from '../menu.service';
import { LayerService } from '../../../../modules/layer/layer.service';
import { SpatialAnalysisService } from '../../../../modules/spatial-analysis/spatial-analysis.service';
import { LoadingService } from '../../loading/loading.service';
import { CacheService } from '../../../../modules/cache/cache.service';
import { ResidentialRealEstateDataService } from '../../../../modules/residential-real-estate/residential-real-estate-data.service';
import { BuildingLandDataService } from '../../../../modules/building-land/building-land-data.service';
import { FontAwesomeService } from '../../../../font-awesome/font-awesome.service';
import { DrawService } from '../../../../shared/services/draw.service';
import { DataModeService } from '../data-mode.service';
import { MinimizationService } from '../minimization.service';
import { SpatialSelectionService } from 'src/app/modules/report/spatial-selection.service';
import {PortfolioDataService} from "../../../../modules/portfolio/portfolio-data.service";
import {ElementType, InteractionType} from "../../../../shared/contract/Enum/TechnicalProtocol";
import {TechnicalProtocolService} from "../../../services/technical-protocol.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-task-minimize',
  templateUrl: './task-minimize.component.html',
  styleUrls: ['./task-minimize.component.scss']
})
export class TaskMinimizeComponent {
  constructor(
    public menuService: MenuService,
    public fontAwesomeService: FontAwesomeService,
    private layerService: LayerService,
    private spatialAnalysisService: SpatialAnalysisService,
    private markerService: MarkerService,
    private router: Router,
    private location: Location,
    private loadingService: LoadingService,
    private residentialRealEstateDataService: ResidentialRealEstateDataService,
    private buildingLandDataService: BuildingLandDataService,
    private cacheService: CacheService,
    private drawService: DrawService,
    public dataModeService: DataModeService,
    public minimizationService: MinimizationService,
    private portfolioDataService: PortfolioDataService,
    private spatialSelectionService: SpatialSelectionService,
    private technicalProtocolService: TechnicalProtocolService,
    private translationService: TranslateService
  ) {}

  OpenFeature(moduleName: string, category: string, event) {
    this.technicalProtocolService.TechnicalProtocol(InteractionType.Click, ElementType.Button, 'Open-' + moduleName + '-' + category + '-Minimization', event);

    this.loadingService.STmating = false;
    this.markerService.RemoveLayerByName([MapLayer.Measurement]);
    this.loadingService.isLoading.next(true);

    // Get submodule for hierarchical portfolio view (category) - ONLY possible in portfolio module (if overview is minimized, the whole minimized submodules will be grouped under portfolio)
    if (category === 'Portfolio') {
      this.dataModeService.DataSubModes.find(e => e.Module === moduleName).Minimized = false;

      // Minimize submodule
      if (this.router.url.includes('Portfolio/' + this.translationService.instant('routes.ListView'))) {
        this.dataModeService.DataSubModes.find(e => e.Module === 'PortfolioOverview').Minimized = true;
        this.minimizationService.minimized = true;
      } else if (this.router.url.includes('Portfolio/' + this.translationService.instant('routes.Analysis'))) {
        this.dataModeService.DataSubModes.find(e => e.Module === 'PortfolioAnalysis').Minimized = true;
        this.minimizationService.minimized = true;
      } else if (this.router.url.includes('Portfolio/' + this.translationService.instant('routes.ObjectView'))) {
        this.dataModeService.DataSubModes.find(e => e.Module === 'PortfolioObject').Minimized = true;
        this.minimizationService.minimized = true;
      }

      // Navigate to selected submodule
      if (moduleName === 'PortfolioOverview') {
        this.router.navigate(['Portfolio', 'ListView'], {
          queryParams: {
            PortfolioId: this.portfolioDataService.SelectedPortfolio.Id,
            Seitenlaenge: this.portfolioDataService.pageSize,
            Seite: (this.portfolioDataService.pageIndex + 1)
          }
        }).then();
      } else if (moduleName === 'PortfolioAnalysis') {
        this.router.navigate(['Portfolio', 'Analysis'], {
          queryParams: {
            PortfolioId: this.portfolioDataService.SelectedPortfolio.Id
          }
        }).then();
      } else if (moduleName === 'PortfolioObject') {
        this.router.navigate(['Portfolio', 'ObjectView'], {
          queryParams: {
            PortfolioId: this.portfolioDataService.SelectedPortfolio.Id,
            ObjektId: this.portfolioDataService.PortfolioObject.Id,
            Jahr: this.portfolioDataService.PortfolioObject.SelectQualifyingDate.Year,
            Quartal: this.portfolioDataService.PortfolioObject.SelectQualifyingDate.Quarter
          }
        }).then();
      }
    } else {
      // Set minimized module in separate variable and reset minimization variable in global allowed modes
      this.minimizationService.wasMinimized = this.dataModeService.AllowedModes.find(e => e.Module === moduleName);
      this.dataModeService.AllowedModes.find(e => e.Module === moduleName).Minimized = false;

      // Reset minimization if the feature will be opened
      if (moduleName === this.dataModeService.DataMode?.Module) {
        this.dataModeService.AllowedModes.find(e => e.Module === this.dataModeService.DataMode.Module).Minimized = false;
      }

      // If feature should be opened and another module is open the minimized variable will be set by router url
      if (this.router.url.includes(this.translationService.instant('routes.ResidentialRealEstate'))) {
        this.dataModeService.AllowedModes.find(e => e.Module === 'ResidentialRealEstate').Minimized = true;
        this.minimizationService.minimized = true;
      } else if (this.router.url.includes(this.translationService.instant('routes.CommercialRealEstate'))) {
        this.dataModeService.AllowedModes.find(e => e.Module === 'CommercialRealEstate').Minimized = true;
        this.minimizationService.minimized = true;
      } else if (this.router.url.includes(this.translationService.instant('routes.BuildingLand'))) {
        this.dataModeService.AllowedModes.find(e => e.Module === 'BuildingLand').Minimized = true;
        this.minimizationService.minimized = true;
      } else if (this.router.url === '/' + this.translationService.instant('routes.ThematicMap')) {
        this.dataModeService.AllowedModes.find(e => e.Module === 'Layer').Minimized = true;
        this.minimizationService.minimized = true;
      } else if (this.router.url === '/' + this.translationService.instant('routes.SpatialAnalysis')) {
        this.dataModeService.AllowedModes.find(e => e.Module === this.dataModeService.DataMode.Module).Minimized = true;
        this.minimizationService.minimized = true;
      } else if (this.router.url.includes('/Portfolio/' + this.translationService.instant('routes.PortfolioOverview'))) {
        this.minimizationService.minimizedSubModule = false;
        this.dataModeService.AllowedModes.find(e => e.Module === 'Portfolio').Minimized = true;
      } else if (this.router.url.includes('Portfolio/' + this.translationService.instant('routes.Analysis'))) {
        this.minimizationService.minimizedSubModule = false;
        this.dataModeService.AllowedModes.find(e => e.Module === 'Portfolio').Minimized = true;
        this.dataModeService.DataSubModes.find(e => e.Module === 'PortfolioAnalysis').Minimized = true;
      } else if (this.router.url.includes('/Portfolio/' + this.translationService.instant('routes.ListView'))) {
        this.minimizationService.minimizedSubModule = false;
        this.dataModeService.AllowedModes.find(e => e.Module === 'Portfolio').Minimized = true;
        this.dataModeService.DataSubModes.find(e => e.Module === 'PortfolioOverview').Minimized = true;
      } else if (this.router.url.includes('/Portfolio/' + this.translationService.instant('routes.ObjectView'))) {
        this.minimizationService.minimizedSubModule = false;
        this.dataModeService.AllowedModes.find(e => e.Module === 'Portfolio').Minimized = true;
        this.dataModeService.DataSubModes.find(e => e.Module === 'PortfolioObject').Minimized = true;
      }


      if (category !== 'SpatialAnalysis') {
        // Stop drawing
        this.drawService.StopDraw([MapLayer.Measurement], true);
        if (moduleName === 'Portfolio') { this.cacheService.isCache = true; }
        // Set the mode to the selected minimized
        this.menuService.SetMode(moduleName).then();

        // Open minimized object by module name
        if (moduleName === 'ResidentialRealEstate') {
          this.cacheService.isCache = true;
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
            this.router.navigate(['ResidentialRealEstate'], {
              queryParams: {
                Latitude: this.residentialRealEstateDataService.ResidentialRealEstateRequestData.Address.Coordinate.Latitude,
                Longitude: this.residentialRealEstateDataService.ResidentialRealEstateRequestData.Address.Coordinate.Longitude,
                Country: this.residentialRealEstateDataService.ResidentialRealEstateRequestData.Address.Coordinate.Country
              }
            })
          )
        } else if (moduleName === 'CommercialRealEstate') {
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
              this.router.navigateByUrl('/CommercialRealEstate')
          )
        } else if (moduleName === 'BuildingLand') {
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
            this.router.navigate(['BuildingLand'], {
              queryParams: {
                Latitude: this.buildingLandDataService.BuildingLandRequestData.Latitude,
                Longitude: this.buildingLandDataService.BuildingLandRequestData.Longitude,
                Country: this.buildingLandDataService.BuildingLandRequestData.Country,
                Area: this.buildingLandDataService.BuildingLandRequestData.PlotSize
              }
            })
          )
        } else if (moduleName === 'Portfolio') {
          if (this.dataModeService.DataSubModes.find(e => e.Module === moduleName) !== undefined) {
            this.dataModeService.DataSubModes.find(e => e.Module === moduleName).Minimized = true;
          }
          this.minimizationService.minimizedSubModule = true;
          this.router.navigate(['Portfolio', 'PortfolioOverview'], {
            queryParams: {
              PortfolioId: this.portfolioDataService.SelectedPortfolio.Id
            }
          }).then();
        }
      } else {
        // If the mode is spatial analysis, stop the draw
        this.drawService.StopDraw([MapLayer.Measurement], true);
        this.spatialAnalysisService.LastIsCache = true;
        this.router.navigate(['SpatialAnalysis']).then();
      }
    }

    if (moduleName !== 'Layer') {
      if (this.dataModeService.AllowedModes.find(e => e.Selected === true) !== undefined) {
        this.dataModeService.AllowedModes.find(e => e.Selected === true).Selected = false;
      }
      if (this.dataModeService.AllowedModes.find(e => e.Module === moduleName) !== undefined) {
        this.dataModeService.AllowedModes.find(e => e.Module === moduleName).Selected = true;
      } else {
        this.dataModeService.AllowedModes.find(e => e.Module === 'Portfolio').Selected = true;
      }
    } else {
      if (this.dataModeService.AllowedModes.find(e => e.Selected === true) !== undefined) {
        this.dataModeService.AllowedModes.find(e => e.Selected === true).Selected = false;
      }
    }

    if (this.dataModeService.AllowedModes.find(e => e.Module === moduleName) === undefined && this.dataModeService.DataSubModes.find(e => e.Module === moduleName) !== undefined) {
      this.dataModeService.DataMode = this.dataModeService.AllowedModes.find(e => e.Module === 'Portfolio');
    } else {
      this.dataModeService.DataMode = this.dataModeService.AllowedModes.find(e => e.Module === moduleName);
    }
    

    if (this.dataModeService.AllowedModes.find(e => e.Minimized === true) === undefined && this.dataModeService.DataSubModes.find(e => e.Minimized === true) === undefined) {
      this.minimizationService.minimized = false;
    }
  }

  // Reset minimization view globally
  CloseAll(event) {
    this.technicalProtocolService.TechnicalProtocol(InteractionType.Click, ElementType.Icon, 'Close-All-Minimization', event);
    this.spatialSelectionService.screenSize.showReportView = false;
    
    if (!this.location.path().includes('Portfolio')) {
      if (this.router.url === ('/' + this.translationService.instant('routes.ThematicMap'))) {
        this.markerService.RemoveAllMapLayer();
      } else if (this.router.url.includes(this.translationService.instant('routes.ResidentialRealEstate')) || this.router.url.includes(this.translationService.instant('routes.BuildingLand'))) {
        this.layerService.RemoveLayer();
        this.markerService.RemoveLayerByName([MapLayer.Measurement]);
      } else if (this.router.url === '/' + this.translationService.instant('routes.SpatialAnalysis')) {
        this.layerService.RemoveLayer();
        this.markerService.RemoveLayerByName([
          MapLayer.Address,
          MapLayer.Marker,
          MapLayer.CompareObjectsPurchasePurchase,
          MapLayer.CompareObjectsPurchaseOffer,
          MapLayer.CompareObjectsPurchaseExpert,
          MapLayer.CompareObjectsRentCurrent,
          MapLayer.CompareObjectsRentFixed,
          MapLayer.CompareObjectsRentLease,
          MapLayer.CompareObjectsRentOffer,
          MapLayer.Perimeter]);
      } else {
        this.layerService.RemoveLayer();
        this.markerService.RemoveAllMapLayer();
      }

      this.minimizationService.wasMinimized = new IconFeature;
    } else {
      this.router.navigate(['Portfolio', 'PortfolioOverview'], {
        queryParams: {
          PortfolioId: this.portfolioDataService.SelectedPortfolio.Id
        }
      }).then();
      this.layerService.RemoveLayer();
      this.markerService.RemoveLayerByName([
        MapLayer.Address,
        MapLayer.Marker,
        MapLayer.CompareObjectsPurchasePurchase,
        MapLayer.CompareObjectsPurchaseOffer,
        MapLayer.CompareObjectsPurchaseExpert,
        MapLayer.CompareObjectsRentCurrent,
        MapLayer.CompareObjectsRentFixed,
        MapLayer.CompareObjectsRentLease,
        MapLayer.CompareObjectsRentOffer,
        MapLayer.Perimeter
      ]);
    }

    // Set all activated layer to deactivated
    this.layerService.LayerOverview.LayerGroup.forEach(e => {
      e.LayerInfos.forEach(f => f.IsActivated = false);
    });

    this.dataModeService.DataSubModes.map(e => e.Minimized = false);
    this.dataModeService.AllowedModes.map(e => e.Minimized = false);
    this.minimizationService.minimizedCoordinate.splice(0, this.minimizationService.minimizedCoordinate.length);
    this.minimizationService.minimized = false;

    // Reset objects if not opened
    if (!this.router.url.includes(this.translationService.instant('routes.ResidentialRealEstate'))) {
      this.residentialRealEstateDataService.ResidentialRealEstateData = new ResidentialRealEstateResponse();
    }
    if (!this.router.url.includes(this.translationService.instant('routes.BuildingLand'))) {
      this.buildingLandDataService.BuildingLandData = new BuildingLandResponse();
    }
    if (!this.router.url.includes(this.translationService.instant('routes.SpatialAnalysis'))) {
      this.spatialAnalysisService.SpatialAnalysisData = new SpatialResponse();
    }
  }
}

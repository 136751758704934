import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  public LoadingImage = '';

  constructor(
    public loadingService: LoadingService
  ) { }

  ngOnInit() {
    // Select loading screen image
    if (this.loadingService.STmating) {
      this.LoadingImage = './assets/load_STmating.png';
    } else {
      this.LoadingImage = './assets/load_STmate.png';
    }
  }

}

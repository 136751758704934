import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    HttpClient,
    HTTP_INTERCEPTORS,
    provideHttpClient,
    withInterceptorsFromDi
} from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BasemapModule } from './core/modules/basemap/basemap.module';
import { MenuModule } from './core/modules/menu/menu.module';
import { TranslateLoader, TranslateModule, TranslateCompiler} from '@ngx-translate/core';
import { TranslateMessageFormatCompiler} from 'ngx-translate-messageformat-compiler';
import { NgxMatPaginatorIntl } from './shared/contract/Paging/ngx-paginator-intl';
import cacheBusting from '../../i18n-cache-busting.json';
import {LoadingModule} from "./core/modules/loading/loading.module";
import {NotificationModules} from "./core/modules/notification/notification.modules";
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {HttpRequestInterceptor} from "./core/interceptor/httprequestinterceptor";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {LayerModule} from "./modules/layer/layer.module";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import {NgxTranslateRoutesModule} from "ngx-translate-routes";
import {ConfirmationModule} from "./core/modules/confirmation/confirmation.module";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from "@angular/material/tooltip";

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/');
}

export function TranslateMessageFormatCompilerFactory() {
  return new TranslateMessageFormatCompiler();
}

export class HttpLoader implements TranslateLoader {
    constructor(private http: HttpClient) {}

    getTranslation(lang: string) {
        const path = './assets/i18n/' + lang + '.json?v=' + cacheBusting[lang];
        return this.http.get(path);
    }
}

@NgModule({
    declarations: [
        AppComponent
    ],
    bootstrap: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        BasemapModule,
        MenuModule,
        NotificationModules,
        LoadingModule,
        FontAwesomeModule,
        LayerModule,
        ConfirmationModule,
        TranslateModule.forRoot({
            defaultLanguage: 'de',
            useDefaultLang: true,
            loader: {
                provide: TranslateLoader,
                useClass: HttpLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            compiler: {
                provide: TranslateCompiler,
                useFactory: TranslateMessageFormatCompilerFactory
            }
        }),
        NgxTranslateRoutesModule.forRoot({
            enableRouteTranslate: true,
            enableTitleTranslate: true,
            enableQueryParamsTranslate: false
        })
    ],
    providers: [
        { provide: MatPaginatorIntl, useClass: NgxMatPaginatorIntl },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' }},
        { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: {
                showDelay: 0,
                hideDelay: 0,
                // touchendHideDelay: 1500,
                // position: 'above',
                disableTooltipInteractivity: true
            }
        },
        provideHttpClient(withInterceptorsFromDi())
    ]
})

export class AppModule {}

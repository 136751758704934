import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import {MatPaginatorIntl} from "@angular/material/paginator";

@Injectable()
export class NgxMatPaginatorIntl extends MatPaginatorIntl {
    constructor(
        private translateService: TranslateService
    ) {
        super(); 
        this.translateService.onLangChange.subscribe(() => {
            this.getPaginatorIntl();
        });
        this.getPaginatorIntl();
    }

    rangeLabel = (page: number, pageSize: number, length: number) => {
        if (length == 0 || pageSize == 0) { return '0' + this.translateService.instant('Properties.Paging.Range') + length; }
          length = Math.max(length, 0);
      
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;
      
        return (startIndex + 1) + ' - ' + endIndex + ' ' + this.translateService.instant('Properties.Paging.Range') + ' ' + length;
    }

    getPaginatorIntl() {
        this.itemsPerPageLabel = this.translateService.instant('Properties.Paging.ItemsPerPage');
        this.firstPageLabel = this.translateService.instant('Properties.Paging.FirstPage');
        this.nextPageLabel = this.translateService.instant('Properties.Paging.NextPage');
        this.previousPageLabel = this.translateService.instant('Properties.Paging.PreviousPage');
        this.lastPageLabel = this.translateService.instant('Properties.Paging.LastPage');
        this.getRangeLabel = this.rangeLabel;
    }
}

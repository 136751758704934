import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ClickType} from '../../../../shared/contract/Enum/Clicktype';
import {BasemapService} from '../basemap.service';
import {MenuService} from '../../menu/menu.service';
import {LoadingService} from '../../loading/loading.service';
import {CompareObjectsService} from '../../../../modules/compare-objects/compare-objects.service';
import {Coordinate, ObjectCoordinate} from '../../../../shared/contract/Address/Coordinates';
import {EPSGType} from '../../../../shared/contract/Enum/EPSG';
import {DataModeService} from '../../menu/data-mode.service';
import {MinimizationService} from '../../menu/minimization.service';
import {LayerService} from '../../../../modules/layer/layer.service';
import {PermissionService} from 'src/app/core/services/permission.service';
import {InterfaceStatus} from 'src/app/shared/contract/Enum/Status';
import {ValueFixingService} from 'src/app/modules/value-fixing/value-fixing.service';
import {SpatialSelectionService} from 'src/app/modules/report/spatial-selection.service';
import {InitService} from 'src/app/core/services/init.service';
import {PopupService} from "../../popup/popup.service";
import {NotificationService} from "../../notification/notification.service";
import {NotificationType} from "../../../../shared/contract/Enum/NotificationType";
import {TechnicalProtocolService} from "../../../services/technical-protocol.service";
import {ElementType, InteractionType} from "../../../../shared/contract/Enum/TechnicalProtocol";
import {firstValueFrom} from "rxjs";
import {AddressSearchService} from "../../../../modules/address-search/address-search.service";
import {MapLayer} from "../../../../shared/contract/Enum/MapLayer";
import {Marker} from "../../../../shared/contract/Enum/Marker";
import {Stroke} from "ol/style";
import {MarkerConfig} from "../../../../shared/contract/Map/MarkerConfig";
import {MarkerService} from "../../../../shared/services/marker.service";

@Component({
  selector: 'app-basemap',
  templateUrl: './basemap.component.html',
  styleUrls: ['./basemap.component.scss']
})
export class BasemapComponent implements OnInit {
  @ViewChild('map') map: ElementRef;

  constructor(
    public baseMapService: BasemapService,
    public menuService: MenuService,
    public spatialSelectionService: SpatialSelectionService,
    private loadingService: LoadingService,
    private translationService: TranslateService,
    private compareObjectService: CompareObjectsService,
    private permissionService: PermissionService,
    private dataModeService: DataModeService,
    private minimizationService: MinimizationService,
    private layerService: LayerService,
    private valueFixingService: ValueFixingService,
    private initService: InitService,
    private popupService: PopupService,
    private notificationService: NotificationService,
    private technicalProtocolService: TechnicalProtocolService,
    private addressSearchService: AddressSearchService,
    private markerService: MarkerService
  ) { }

  ngOnInit() {
    this.baseMapService.InitMap();
    this.layerService.SetBaseLayer();
  }

  async OnDoubleClick (event: MouseEvent) {
    this.technicalProtocolService.TechnicalProtocol(InteractionType.Click, ElementType.MapElement, 'DoubleClick-Map', event);

    // Double click is only activated for the object request (residential, commercial, building land)
    if (this.dataModeService.DataMode?.Category !== 'SpatialAnalysis' && this.dataModeService.DataMode?.Module !== 'Portfolio') {
      this.baseMapService.CurrentSelectedMarkerCoordinate = new Coordinate(this.baseMapService.Map.getEventCoordinate(event), EPSGType.OSM);

      // Check if user has right to request in the selected country
      const countryRight = await firstValueFrom(this.permissionService.GetCountryRight({
        Coordinate: {
          Country: null,
          Latitude: this.baseMapService.CurrentSelectedMarkerCoordinate.Get(EPSGType.LonLat).Y,
          Longitude: this.baseMapService.CurrentSelectedMarkerCoordinate.Get(EPSGType.LonLat).X} as ObjectCoordinate,
          ModuleId: this.initService.InitData.ModuleRight.find(e => e.Name === this.dataModeService.DataMode?.Module)?.Id
      }));

      this.baseMapService.Country.next(countryRight.Country);

      // Check if the zoom level is high enough, the country is not null or the interface returns an error code
      if (this.baseMapService.GetZoomLevel() < 15) {
        this.notificationService.Open(NotificationType.Info, this.translationService.instant('Exception.Header.Info'), this.translationService.instant('Exception.Info.Zoom'), 3)
      } else if (countryRight.Country === 'XXX') {
        this.notificationService.Open(NotificationType.Info, this.translationService.instant('Exception.Header.Info'), this.translationService.instant('Exception.Info.STmate'), 3)
      } else if (this.dataModeService.DataMode === null ||
          (this.dataModeService.DataMode?.Module !== 'ResidentialRealEstate' &&
           this.dataModeService.DataMode?.Module !== 'CommercialRealEstate' &&
           this.dataModeService.DataMode?.Module !== 'BuildingLand' &&
           this.dataModeService.DataMode?.Category !== 'SpatialAnalysis')) {
        this.notificationService.Open(NotificationType.Info, this.translationService.instant('Exception.Header.Info'), this.translationService.instant('Exception.Info.SelectedMode'), 3)
      } else if ((this.dataModeService.DataMode.Module === 'CommercialRealEstate' && countryRight.Country !== 'de') ||
          (this.dataModeService.AllowedModes.find(e => e.Selected)?.Module === 'CommercialRealEstate' && countryRight.Country !== 'de') ||
          (this.dataModeService.DataMode.Module === 'BuildingLand' && (countryRight.Country !== 'at' && countryRight.Country !== 'be')) ||
          (this.dataModeService.AllowedModes.find(e => e.Selected)?.Module === 'BuildingLand' && (countryRight.Country !== 'at' && countryRight.Country !== 'be'))) {
        this.notificationService.Open(NotificationType.Failure, this.translationService.instant('Exception.Header.Failure'), this.translationService.instant('Exception.Failure.' + this.dataModeService.DataMode?.Module + '.State'), 3)
      } else if (countryRight.InterfaceStatus !== InterfaceStatus.OK) {
        if (countryRight.InterfaceStatus === InterfaceStatus.ActivePackagePromoNotInCountry) {
          this.notificationService.Open(NotificationType.Failure, this.translationService.instant('Exception.Header.Failure'), this.translationService.instant('Exception.Failure.Status.' + countryRight.InterfaceStatus, {Country: this.translationService.instant('Properties.Country.' + countryRight.Country)}), 3)
        } else {
          this.notificationService.Open(NotificationType.Failure, this.translationService.instant('Exception.Header.Failure'), this.translationService.instant('Exception.Failure.Status.' + countryRight.InterfaceStatus), 3)
        }
      } else {
        // Reset the minimization
        if (this.dataModeService.AllowedModes.find(e => e.Minimized === true && e?.Module === this.dataModeService.DataMode?.Module) !== undefined) {
          this.dataModeService.AllowedModes.find(e => e.Minimized === true && e?.Module === this.dataModeService.DataMode?.Module).Minimized = false;
        }

        // Reset change-detection variables
        this.compareObjectService.changedAdaptation = false;
        this.valueFixingService.changedValueFixing = false;
        this.minimizationService.wasMinimized = undefined;
        // Initialize loading screen
        this.loadingService.STmating = true;
        this.loadingService.isLoading.next(true);

        // Report address nearby click
        if (this.dataModeService.DataMode?.Module === 'ResidentialRealEstate') {
          this.addressSearchService.proposedAddress = await firstValueFrom(this.addressSearchService.GetAddressesNearBy({
            Country: countryRight.Country,
            Latitude: this.baseMapService.CurrentSelectedMarkerCoordinate.Get(EPSGType.LonLat).Y,
            Longitude: this.baseMapService.CurrentSelectedMarkerCoordinate.Get(EPSGType.LonLat).X
          }))

          if (this.addressSearchService.proposedAddress.length > 1) {
            this.markerService.RemoveLayerByName([MapLayer.Marker]);
            this.markerService.SetMarker([{
              Name: ['STmate-Icon'],
              Point: this.baseMapService.CurrentSelectedMarkerCoordinate,
              Source: Marker.LocationStreet,
              Scale: 2.5,
              Baseline: 'middle',
              Alignment: 'center',
              Color: '#a2c510',
              Stroke: new Stroke({ color: '#32547b', width: 2 }),
              Radius: null
            } as MarkerConfig], MapLayer.Marker, 7);

            this.notificationService.Open(NotificationType.Info, this.translationService.instant('Exception.Header.Info'), this.translationService.instant('Exception.Info.MultiAddress'), 3)
            this.loadingService.isLoading.next(false);
          }
        }

        // Execute mode
        if (this.addressSearchService.proposedAddress.length <= 1 && this.dataModeService.DataMode?.Module === 'ResidentialRealEstate' || this.dataModeService.DataMode?.Module !== 'ResidentialRealEstate') {
          await this.menuService.ExecuteMode(event, countryRight.Country, ClickType.DoubleClick);
        }
      }
    }
  }

  // Remove popups on right click
  OnRightClick = (event: MouseEvent) => {
    this.technicalProtocolService.TechnicalProtocol(InteractionType.Click, ElementType.MapElement, 'RightClick-Map', event);
    this.popupService.ClosePopup(this.popupService.activePopup);
  }

  // Allow drawing if mode is spatial analysis
  OnClick = (event: MouseEvent) => {
    if (this.dataModeService.DataMode?.Category === 'SpatialAnalysis') {
      this.menuService.ExecuteMode(event, null, ClickType.Click).then();
    }
  }

  // Functionality to open the menu
  OnMouseMove = (event: MouseEvent) => {
    const height = (this.map.nativeElement as HTMLElement).clientHeight;
    this.menuService.taskBarConfig.ShowMenu = (height - event['layerY']) < 200;
  }
}

<div menu *ngIf="!menuService.taskBarConfig.ShowMenu">
  <div menu-icon>
    <button mat-fab color="accent" (click)="OpenMenu($event)">
      <fa-icon [icon]="fontAwesomeService.LightIcons.Bars"></fa-icon>
    </button>
  </div>
</div>
<div task-info>
  <div inner-task-info>
    <a (click)="technicalProtocolService.TechnicalProtocol(technicalProtocolService.interactionTypes.Click, technicalProtocolService.elementTypes.Link, 'OSM-Reference', $event)" [innerHTML]="'Module.Menu.Taskbar.TaskInfo.Reference.OSM' | translate"></a>
    <a (click)="technicalProtocolService.TechnicalProtocol(technicalProtocolService.interactionTypes.Click, technicalProtocolService.elementTypes.Link, 'ONGEO-Reference', $event)" [innerHTML]="'Module.Menu.Taskbar.TaskInfo.Reference.ONGEO' | translate"></a>
    {{ 'Module.Address.Coordinates.Coordinates' | translate }}: {{ baseMapService.MouseCoordinates }} ||
    {{ 'Module.Menu.Taskbar.TaskInfo.Zoom' | translate }}: {{ baseMapService.GetZoomLevel() | number: '1.0-0' }} ||
    {{ 'Module.Address.Country' | translate }}: {{ Land.toUpperCase() }} ||
    {{ 'Module.Menu.Taskbar.TaskInfo.Mode' | translate }}: {{ this.GetSelectedDataMode() }}
    <div id="scale-bar-position"></div>
  </div>
</div>

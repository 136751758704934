import { Component } from '@angular/core';
import { trigger, style, transition, animate } from '@angular/animations';
import { PermissionService } from '../../../services/permission.service';
import { MenuService } from '../menu.service';
import { DataModeService } from '../data-mode.service';

@Component({
  selector: 'app-task-bar',
  templateUrl: './task-bar.component.html',
  styleUrls: ['./task-bar.component.scss'],
  animations: [
    trigger(
      'enterMenu', [
        transition(':enter', [
          style({opacity: 0}),
          animate(500, style({opacity: 1}))
        ]),
        transition(':leave', [
          animate(300, style({opacity: 0}))
        ])
      ]
    )
  ]
})
export class TaskBarComponent {

  constructor(
    public permissionService: PermissionService,
    public menuService: MenuService,
    public dataModeService: DataModeService
  ) {
    this.menuService = menuService;
  }

  // Get column count to define grid
  GetColumns(value: number): string {
    let format = '';
    for (let i = 0; i < value; i++) {
      format += ' 1fr';
    }
    return format;
  }
}

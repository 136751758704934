import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PermissionGuard } from './core/guard/permission.guard';

const routes: Routes = [
  {
    path: '',
    title: 'STmate'
  },
  {
    path: 'AddressSearch',
    loadChildren: () => import('./modules/address-search/address-search.module').then(m => m.AddressSearchModule),
    canActivate: [PermissionGuard]
  },
  {
    path: 'ThematicMap',
    loadChildren: () => import('./modules/layer/layer.module').then(m => m.LayerModule)
  },
  {
    path: 'ResidentialRealEstate',
    loadChildren: () => import('./modules/residential-real-estate/residential-real-estate.module').then(m => m.ResidentialRealEstateModule),
    canActivate: [PermissionGuard]
  },
  {
    path: 'CommercialRealEstate',
    loadChildren: () => import('./modules/commercial-real-estate/commercial-real-estate.module').then(m => m.CommercialRealEstateModule),
    canActivate: [PermissionGuard]
  },
  {
    path: 'BuildingLand',
    loadChildren: () => import('./modules/building-land/building-land.module').then(m => m.BuildingLandModule),
    canActivate: [PermissionGuard]
  },
  {
    path: 'SpatialAnalysis',
    loadChildren: () => import('./modules/spatial-analysis/spatial-analysis.module').then(m => m.SpatialAnalysisModule),
    canActivate: [PermissionGuard]
  },
  {
    path: 'Portfolio',
    loadChildren: () => import('./modules/portfolio/portfolio.module').then(m => m.PortfolioModule)
  },
  {
    path: 'Cache',
    loadChildren: () => import('./modules/cache/cache.module').then(m => m.CacheModule)
  },
  {
    path: 'Settings',
    loadChildren: () => import('./modules/option/option.module').then(m => m.OptionModule)
  },
  {
    path: 'Reception',
    loadChildren: () => import('./core/modules/reception/reception.module').then(m => m.ReceptionModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div minimize >
    <div *ngFor="let item of dataModeService.AllowedModes">
        <div settings *ngIf="item.Module !== 'Separator' && item.Minimized && (item.Module === 'CurrentPosition' || item.Module === 'Portfolio' || item.Module === 'Cache' || item.Module === 'Settings')" 
            matTooltip="{{ 'Title.MainTitle.' + item.Module | translate }}"
            [matTooltipPosition]="'right'">
            <fa-icon [icon]=item.Icon class="fa-lg" (click)="OpenFeature(item.Module, item.Category, $event)"></fa-icon>
        </div>
        <div settings *ngIf="item.Module !== 'Separator' && item.Minimized && (item.Module !== 'CurrentPosition' && item.Module !== 'Portfolio' && item.Module !== 'Cache' && item.Module !== 'Settings')" 
            matTooltip="{{ 'Title.MainTitle.' + item.Module + '.Part1' | translate }}{{ 'Title.MainTitle.' + item.Module + '.Part2' | translate }}"
            [matTooltipPosition]="'right'">
            <fa-icon [icon]=item.Icon class="fa-lg" (click)="OpenFeature(item.Module, item.Category, $event)"></fa-icon>
        </div>
    </div>
    <div *ngIf="minimizationService.minimizedSubModule">
        <div *ngFor="let subItem of dataModeService.DataSubModes">
            <div settings *ngIf="subItem.Minimized"
                matTooltip="{{ 'Title.MainTitle.' + subItem.Module + '.Part1' | translate }}{{ 'Title.MainTitle.' + subItem.Module + '.Part2' | translate }}"
                [matTooltipPosition]="'right'">
                <fa-icon [icon]=subItem.Icon class="fa-lg" (click)="OpenFeature(subItem.Module, subItem['Category'], $event)"></fa-icon>
            </div>
        </div>
    </div>
    <div settings *ngIf="minimizationService.minimized" matTooltip="{{ 'Title.MainTitle.CloseAll' | translate }}" [matTooltipPosition]="'right'">
        <fa-icon [icon]="fontAwesomeService.LightIcons.TimesCircle" class="fa-lg" (click)="CloseAll($event)"></fa-icon>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { trigger, style, transition, animate } from '@angular/animations';
import { BasemapService } from '../../basemap/basemap.service';
import { MenuService } from '../menu.service';
import { FontAwesomeService } from '../../../../font-awesome/font-awesome.service';
import { DataModeService } from '../data-mode.service';
import { TranslateService } from '@ngx-translate/core';
import {TechnicalProtocolService} from "../../../services/technical-protocol.service";
import {ElementType, InteractionType} from "../../../../shared/contract/Enum/TechnicalProtocol";
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'app-task-info',
  templateUrl: './task-info.component.html',
  styleUrls: ['./task-info.component.scss'],
  animations: [    
    trigger(
      'enterMenu', [
        transition(':enter', [
          style({opacity: 0}),
          animate(500, style({opacity: 1}))
        ]),
        transition(':leave', [
          animate(300, style({opacity: 0}))
        ])
      ]
    )
  ]
})
export class TaskInfoComponent implements OnInit {
  public Zoom = '0';
  public Land = 'DE';

  constructor(
    public fontAwesomeService: FontAwesomeService,
    public menuService: MenuService,
    public baseMapService: BasemapService,
    public dataModeService: DataModeService,
    public technicalProtocolService: TechnicalProtocolService,
    private translationService: TranslateService
  ) { }

  async ngOnInit() {
    this.Land = await firstValueFrom(this.baseMapService.Country);
  }

  // Get name for selected mode
  GetSelectedDataMode() {
    if (this.dataModeService.AllowedModes?.find(e => e.Selected) === null || this.dataModeService.AllowedModes?.find(e => e.Selected) === undefined) {
      return this.translationService.instant('Module.Menu.Taskbar.TaskInfo.DefaultMode')
    } else {
      return this.dataModeService.AllowedModes.find(e => e.Selected).Module === 'Portfolio' ? 
              this.translationService.instant('Title.MainTitle.' + this.dataModeService.AllowedModes.find(e => e.Selected).Module) :
              this.translationService.instant('Title.MainTitle.' + this.dataModeService.AllowedModes.find(e => e.Selected).Module + '.Part1') + this.translationService.instant('Title.MainTitle.' + this.dataModeService.AllowedModes.find(e => e.Selected).Module + '.Part2');
    }
  }

  OpenMenu(event: Event) {
    this.menuService.taskBarConfig.ShowMenu = true;
    this.technicalProtocolService.TechnicalProtocol(InteractionType.Click, ElementType.Icon, 'Menu', event);
  }
}

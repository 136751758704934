import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../../material/material.module';
import { FontAwesomeIconModule } from '../../../font-awesome/font-awesome-icon.module';
import { TaskBarComponent } from './task-bar/task-bar.component';
import { TaskIconComponent } from './task-icon/task-icon.component';
import { TaskInfoComponent } from './task-info/task-info.component';
import { TaskMinimizeComponent } from './task-minimize/task-minimize.component';
import {BasemapModule} from "../basemap/basemap.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MaterialModule,
        TranslateModule,
        FontAwesomeIconModule,
        BasemapModule,
        NgOptimizedImage
    ],
  declarations: [
    TaskBarComponent,
    TaskIconComponent,
    TaskInfoComponent,
    TaskMinimizeComponent
  ],
  exports: [
    TaskBarComponent,
    TaskMinimizeComponent
  ]
})
export class MenuModule { }

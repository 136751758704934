<ng-container *ngIf="Config.Category !== 'Separator' && (Config.Module === 'CurrentPosition' || Config.Module === 'Portfolio' || Config.Module === 'Cache' || Config.Module === 'Settings'); else splitIcon">
  <div 
    matRipple tab 
    matTooltip="{{ 'Title.MainTitle.' + Config.Module | translate }}"
    (click)="Click($event)"
    [ngClass]="{ selected: Config.Selected }">
    <fa-icon [icon]=Config.Icon></fa-icon>
  </div>
</ng-container>

<ng-template #splitIcon>
  <ng-container *ngIf="Config.Category !== 'Separator' && (Config.Module !== 'CurrentPosition' && Config.Module !== 'Portfolio' && Config.Module !== 'Cache' && Config.Module !== 'Settings'); else separator">
    <div 
      matRipple tab 
      matTooltip="{{ 'Title.MainTitle.' + Config.Module + '.Part1' | translate }}{{ 'Title.MainTitle.' + Config.Module + '.Part2' | translate }}" 
      (click)="Click($event)"
      [ngClass]="{ selected: Config.Selected }">
      <fa-icon [icon]=Config.Icon></fa-icon>
    </div>
  </ng-container>
</ng-template>

<ng-template #separator>
  <div seperator></div>
</ng-template>

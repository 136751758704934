import {Component, Input} from '@angular/core';
import {MenuService} from '../menu.service';
import {IconFeature} from '../../../../shared/contract/Module/DataMode';
import {DrawService} from '../../../../shared/services/draw.service';
import {MarkerService} from '../../../../shared/services/marker.service';
import {MapLayer} from '../../../../shared/contract/Enum/MapLayer';
import {FontAwesomeService} from '../../../../font-awesome/font-awesome.service';
import {DataModeService} from '../data-mode.service';
import {ResidentialRealEstateDataService} from 'src/app/modules/residential-real-estate/residential-real-estate-data.service';
import {BuildingLandDataService} from 'src/app/modules/building-land/building-land-data.service';
import {CommercialRealEstateDataService} from 'src/app/modules/commercial-real-estate/commercial-real-estate-data.service';
import {ResidentialRealEstateResponse} from 'src/app/shared/contract/ResidentialRealEstate/ResidentialRealEstateResponse';
import {CommercialAVMResponse} from 'src/app/shared/contract/CommercialRealEstate/CommercialRealEstateResponse';
import {BuildingLandResponse} from 'src/app/shared/contract/BuildingLand/BuildingLandResponse';
import {CommercialRealEstateRequest} from 'src/app/shared/contract/CommercialRealEstate/CommercialRealEstateRequest';
import {Address} from 'src/app/shared/contract/Address/Address';
import {LoadingService} from "../../loading/loading.service";
import {TechnicalProtocolService} from "../../../services/technical-protocol.service";
import {ElementType, InteractionType} from "../../../../shared/contract/Enum/TechnicalProtocol";


@Component({
  selector: 'app-task-icon',
  templateUrl: './task-icon.component.html',
  styleUrls: ['./task-icon.component.scss']
})
export class TaskIconComponent {
  @Input() Config: IconFeature;

  constructor(
    public fontAwesomeService: FontAwesomeService,
    private menuService: MenuService,
    private drawService: DrawService,
    private loadingService: LoadingService,
    private markerService: MarkerService,
    private dataModeService: DataModeService,
    private residentialRealEstateDataService: ResidentialRealEstateDataService,
    private buildingLandDataService: BuildingLandDataService,
    private commercialRealEstateDataService: CommercialRealEstateDataService,
    private technicalProtocolService: TechnicalProtocolService
  ) { }

  // Click event sets the mode
  Click(event) {
    this.technicalProtocolService.TechnicalProtocol(InteractionType.Click, ElementType.Modus, this.Config.Module, event)

    // Remove spatial analysis geometry
    if (this.dataModeService.DataMode?.Category !== 'SpatialAnalysis' || this.Config?.Category !== 'SpatialAnalysis') {
      if (this.Config.Category !== 'SpatialAnalysis') { this.markerService.RemoveLayerByName([MapLayer.Measurement]) }
      this.loadingService.isLoading.next(true);
    }

    // Stop the draw functionality
    this.drawService.StopDraw([MapLayer.Measurement], true, true);
    if (this.Config?.Category === 'DataRequest' || this.Config?.Category === 'SpatialAnalysis' || this.Config?.Category === 'BulkAnalysis') {
      if (this.dataModeService.AllowedModes.find(e => e.Selected === true) !== undefined) {
        this.dataModeService.AllowedModes.find(e => e.Selected === true).Selected = false;
      }

      // Remove the minimized object, if the same mode will be selected
      if (this.dataModeService.AllowedModes.find(e => e.Module === this.Config.Module).Minimized) {
        this.dataModeService.AllowedModes.find(e => e.Module === this.Config.Module).Minimized = false;
        this.markerService.RemoveAllMapLayer();
        switch (this.Config.Module) {
          case 'ResidentialRealEstate':
            this.residentialRealEstateDataService.ResidentialRealEstateData = new ResidentialRealEstateResponse;
            break;
          case 'CommercialRealEstate':
            this.commercialRealEstateDataService.CommercialRealEstateRequestData = new CommercialRealEstateRequest;
            this.commercialRealEstateDataService.CommercialRealEstateData = new CommercialAVMResponse;
            break;
          case 'BuildingLand':
            this.buildingLandDataService.BuildingLandData = new BuildingLandResponse;
            break;
        }
      }

      // Set mode to selected and initialize module interaction
      this.Config.Selected = true;
      this.menuService.SetMode(this.Config.Module).then();
    } else {
      if (this.dataModeService.AllowedModes.find(e => e.Selected === true) !== undefined) {
        this.dataModeService.AllowedModes.find(e => e.Selected === true).Selected = false;
      }
      // Initialize module interaction
      this.menuService.SetMode(this.Config.Module).then();
    }
  }
}

import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";
import {NotificationComponent} from "./notification/notification.component";
import {MaterialModule} from "../../../material/material.module";
import {NotificationService} from "./notification.service";
import {FontAwesomeIconModule} from "../../../font-awesome/font-awesome-icon.module";
import {TranslateModule} from "@ngx-translate/core";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {FormsModule} from "@angular/forms";

@NgModule({
    imports: [
        CommonModule,
        DragDropModule,
        FormsModule,
        MaterialModule,
        FontAwesomeIconModule,
        TranslateModule
    ],
    declarations: [
        NotificationComponent
    ],
    exports: [
        NotificationComponent
    ],
    providers: [
        NotificationService
    ]
})
export class NotificationModules { }
import {Component} from '@angular/core';
import {PopupService} from "../popup.service";
import {FontAwesomeService} from "../../../../font-awesome/font-awesome.service";
import {CompareObjectsService} from "../../../../modules/compare-objects/compare-objects.service";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {PortfolioDataService} from "../../../../modules/portfolio/portfolio-data.service";
import {ConversionService} from "../../../../shared/services/conversion.service";
import {DecimalPipe} from "@angular/common";
import {OptionService} from "../../../../modules/option/option.service";
import {PortfolioLocationObject} from "../../../../shared/contract/Portfolio/PortfolioLocation";
import {CompareObject} from "../../../../shared/contract/AVM/CompareObject/CompareObject";
import {LoadingService} from "../../loading/loading.service";
import {MinimizationService} from "../../menu/minimization.service";
import {LayerService} from "../../../../modules/layer/layer.service";
import {InfoTextService} from "../../../../shared/services/info-text.service";
import {MapFeatureInfoService} from "../../../../shared/services/map-feature-info.service";
import {ThematicInformationList} from "../../../../shared/contract/View/Component";
import {InterfaceStatus} from "../../../../shared/contract/Enum/Status";
import {TechnicalProtocolService} from "../../../services/technical-protocol.service";
import {ElementType, InteractionType} from "../../../../shared/contract/Enum/TechnicalProtocol";
import {SustainabilityService} from "../../../../modules/compare-objects/sustainability.service";

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent {
  private ObjectInfoText = [
    {Name: 'ConstructionYear', Property: 'ConstructionYear'},
    {Name: 'LivingSpace', Property: 'LivingSpace'},
    {Name: 'ObjectType', Property: 'ObjectSubType'},
    {Name: 'PlotSize', Property: 'PlotSize'},
    {Name: 'PlotType', Property: 'PlotType'}
  ]

  constructor(
      public popupService: PopupService,
      public fontAwesomeService: FontAwesomeService,
      public mapFeatureInfoService: MapFeatureInfoService,
      private loadingService: LoadingService,
      private compareObjectService: CompareObjectsService,
      private sustainabilityServices: SustainabilityService,
      private translationService: TranslateService,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private portfolioDataService: PortfolioDataService,
      private conversionService: ConversionService,
      private decimalPipe: DecimalPipe,
      private optionService: OptionService,
      private minimizationService: MinimizationService,
      private layerService: LayerService,
      private infoTextService: InfoTextService,
      private technicalProtocolService: TechnicalProtocolService
  ) { }

  SetTitleText(subHeader: boolean = false): string {
    switch (this.popupService.Mode) {
      case 'Portfolio':
        return subHeader ?
            this.popupService.SelectedObjectDetails.Address.Zip + ' ' + this.popupService.SelectedObjectDetails.Address.City :
            this.popupService.SelectedObjectDetails.Address.Street + ' ' + this.popupService.SelectedObjectDetails.Address.HouseNumber
      case 'ResidentialRealEstate':
      case 'BuildingLand':
      case 'CommercialRealEstate':
      case 'PortfolioObject':
        return subHeader ? '' : this.translationService.instant('Title.MainTitle.CompareObject')
      case 'Layer':
        return subHeader ?
            this.infoTextService.GetInfoTextByLocale(this.layerService.LayerOverview.LayerGroup.find(e => e.LayerInfos.find(f => f.IsActivated))?.DisplayName)[0]?.Text :
            this.infoTextService.GetInfoTextByLocale(this.layerService.LayerOverview.LayerGroup.find(e => e.LayerInfos.find(f => f.IsActivated))?.LayerInfos?.find(f => f.IsActivated)?.DisplayName)[0]?.Text
    }
  }

  SetButtonText (): string {
    switch (this.popupService.Mode) {
      case 'Portfolio':
        return this.translationService.instant('Button.OpenPortfolioObject')
      case 'ResidentialRealEstate':
      case 'BuildingLand':
      case 'CommercialRealEstate':
      case 'PortfolioObject':
        return this.translationService.instant('Button.Adaptation')
    }
  }

  SetPropertyText(property: string): string {
    if (this.ObjectInfoText.find(e => e.Property === property) !== undefined) {
      return this.translationService.instant('Module.ObjectInformation.' + this.ObjectInfoText.find(e => e.Property === property)?.Name)
    } else if (property === 'Purchase' || property === 'Rent') {
      return this.translationService.instant('Properties.CompareObjectType.' + property + '.' + this.popupService.SelectedObject.CompareType)
    } else if (property === 'TransactionYear') {
      return this.translationService.instant('Table.Header.SalesYear')
    } else if (property === 'CommercialType') {
      return this.translationService.instant('Module.ObjectInformation.ObjectType')
    }
  }

  SetProperty(property: string): string {
    if (property === 'ObjectSubType') {
      return (
          this.translationService.instant('Properties.ObjectType.LongVersion.' + this.conversionService.ConvertObjectSubTypeToObjectType(this.popupService.SelectedObject[this.popupService.Mode === 'Portfolio' ? 'ObjectInformationShort' : 'ObjectInformation'].ObjectSubType)) +
          ' (' + this.translationService.instant('Properties.ObjectSubType.LongVersion.' + this.popupService.SelectedObject[this.popupService.Mode === 'Portfolio' ? 'ObjectInformationShort' : 'ObjectInformation'].ObjectSubType) + ')'
      )
    } else if (property === 'CommercialType') {
      return this.translationService.instant('Properties.ObjectSubType.LongVersion.' + this.popupService.SelectedObject[this.popupService.Mode === 'Portfolio' ? 'ObjectInformationShort' : 'ObjectInformation'].ObjectSubType)
    } else if (property === 'LivingSpace' || property === 'PlotSize') {
      return this.popupService.SelectedObject[this.popupService.Mode === 'Portfolio' ? 'ObjectInformationShort' : 'ObjectInformation'][property] + ' m²'
    } else if (property === 'PlotType') {
      return this.translationService.instant('Properties.PlotType.LongVersion.' + this.popupService.SelectedObject.ObjectInformation.PlotType)
    } else if (property === 'Purchase' || property === 'Rent') {
      return this.decimalPipe.transform(this.popupService.SelectedObject.Prices.Original.Price, '1.0-0', this.optionService.locale) + ' ' + this.popupService.Unit[0]
    } else {
      return this.popupService.SelectedObject[this.popupService.Mode === 'Portfolio' ? 'ObjectInformationShort' : 'ObjectInformation'][property]
    }
  }

  SelectPortfolioObject(locationObject: PortfolioLocationObject, event) {
    this.technicalProtocolService.TechnicalProtocol(InteractionType.Click, ElementType.Selection, 'Select-PortfolioObject-Popup-' + (this.popupService.Mode == 'PortfolioObject' ? 'PortfolioObject-Portfolio' : this.popupService.Mode), event);
    this.popupService.GetPortfolioObjectInfo(locationObject).then();
  }

  SelectCompareObject(compareObject: CompareObject, event) {
    this.technicalProtocolService.TechnicalProtocol(InteractionType.Click, ElementType.Selection, 'Select-CompareObject-Popup-' + (this.popupService.Mode == 'PortfolioObject' ? 'PortfolioObject-Portfolio' : this.popupService.Mode), event);
    this.popupService.SelectedObject = compareObject;
    this.popupService.ObjectInformationList = this.popupService.GetPopupObjectList();
    this.popupService.allowPopupNavigation = true;
    this.compareObjectService.selectedRowId = this.popupService.SelectedObject.Id
    this.compareObjectService.SelectCompareObjectOnMap(this.popupService.SelectedObject as CompareObject);
  }

  SetCompareObjectSelectionText(compareObject: CompareObject): string {
    let selectionText = '';

    selectionText = selectionText + compareObject.PriceDate.Year;
    if (compareObject.PriceDate.Month !== null) { selectionText = selectionText +'-' + compareObject.PriceDate.Month; }

    return selectionText
  }

  RemoveObject(event) {
    this.technicalProtocolService.TechnicalProtocol(InteractionType.Click, ElementType.Icon, 'Close-Popup-' + (this.popupService.Mode == 'PortfolioObject' ? 'PortfolioObject-Portfolio' : this.popupService.Mode), event);
    this.popupService.ClosePopup(this.popupService.activePopup)
  }

  Navigate(event) {
    this.technicalProtocolService.TechnicalProtocol(InteractionType.Click, ElementType.Button, 'Navigate-' + (this.popupService.Mode == 'Portfolio' ? 'PortfolioObject' : 'Adaptation') + '-Popup-' + (this.popupService.Mode == 'PortfolioObject' ? 'PortfolioObject-Portfolio' : this.popupService.Mode), event);

    if (this.popupService.Mode === 'Portfolio') {
      this.loadingService.isLoading.next(true);
      this.minimizationService.HandleMinimization('Portfolio');
      this.portfolioDataService.PortfolioObject.Status = InterfaceStatus.None;
      this.portfolioDataService.thematicInformationPortfolioObject = new ThematicInformationList();
      this.router.navigate(['Portfolio', 'ObjectView'], {
        queryParams: {
          PortfolioId: this.portfolioDataService.SelectedPortfolio.Id,
          ObjektId: this.portfolioDataService.PortfolioObject.Id,
          Jahr: this.portfolioDataService.PortfolioObject.SelectQualifyingDate?.Year,
          Quartal: this.portfolioDataService.PortfolioObject.SelectQualifyingDate?.Quarter
        }
      }).then();
    } else if (this.popupService.Mode === 'PortfolioObject') {
      this.compareObjectService.AdaptionData = this.popupService.SelectedObject;
      this.sustainabilityServices.readOnly = this.portfolioDataService.PortfolioObject.Readonly;
      this.router.navigate(['Portfolio', 'ObjectView', 'Adaptation'], {
        queryParams: {
          PortfolioId: this.portfolioDataService.SelectedPortfolio.Id,
          ObjektId: this.portfolioDataService.PortfolioObject.Id,
          Jahr: this.portfolioDataService.PortfolioObject.SelectQualifyingDate?.Year,
          Quartal: this.portfolioDataService.PortfolioObject.SelectQualifyingDate?.Quarter
        }
      }).then();
    } else {
      this.compareObjectService.AdaptionData = this.popupService.SelectedObject;
      this.sustainabilityServices.readOnly = false;
      this.router.navigate(['Adaptation'], {relativeTo: this.activatedRoute.firstChild}).then();
    }
  }

  GetLegendForMapFeature(color: boolean, mapFeatureValue: string): string {
    const convertedMapFeatureValue = this.conversionService.ConvertStringToNumber(mapFeatureValue);
    const unit = this.mapFeatureInfoService.mapFeatureInfo.Legend[0].Color === null ? ' ' + this.mapFeatureInfoService.mapFeatureInfo.Legend[0].DisplayText : '';
    let value = null;
    let selectedColor = '';

    // Get value and color from legend by selected map feature
    this.mapFeatureInfoService.mapFeatureInfo.Legend?.forEach((legendItem, index) => {
      if (legendItem.DisplayText?.includes('-')) {
        // If legend is a range (10 - 20), check if selected value is within the bounds
        if (convertedMapFeatureValue >= legendItem.DisplayText.split('-').map(e => this.conversionService.ConvertStringToNumber(e))[0] && convertedMapFeatureValue <= legendItem.DisplayText.split('-').map(e => this.conversionService.ConvertStringToNumber(e))[1]) {
          value = mapFeatureValue;
          selectedColor = legendItem.Color;
        }
      } else {
        // If legend is the last object, check if selected value is equal or greater than the bound
        if (index == (this.mapFeatureInfoService.mapFeatureInfo.Legend.length - 1) && convertedMapFeatureValue >= +legendItem.DisplayText?.match(/\d/g)) {
          if (mapFeatureValue >= legendItem.DisplayText?.match(/\d/g)?.join('')) {
            value = mapFeatureValue;
            selectedColor = legendItem.Color;
          }
        } else if (index in [0, 1] && legendItem.DisplayText?.match(/\d/g)) {
          // If legend is the first object, check if selected value is equal or less than the bound
          if (convertedMapFeatureValue <= +legendItem.DisplayText?.match(/\d/g)?.join('')) {
            value = mapFeatureValue;
            selectedColor = legendItem.Color;
          }
        }
      }
    })

    // If there is no numeric range value, show text
    if (!value) {
      value = this.mapFeatureInfoService.mapFeatureInfo.Legend.find(e => e.Value === mapFeatureValue)?.DisplayText !== null && this.mapFeatureInfoService.mapFeatureInfo.Legend.find(e => e.Value === mapFeatureValue)?.DisplayText !== undefined ? this.mapFeatureInfoService.mapFeatureInfo.Legend.find(e => e.Value === mapFeatureValue)?.DisplayText : mapFeatureValue;
      selectedColor = this.mapFeatureInfoService.mapFeatureInfo.Legend.find(e => e.Value === mapFeatureValue)?.Color;
    }

    this.popupService.allowPopupNavigation = false;
    return color ? selectedColor : value + unit;
  }
}

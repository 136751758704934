import { Component } from '@angular/core';
import { PermissionService } from '../../../services/permission.service';
import { DataModeService } from '../../menu/data-mode.service';
import {BasemapService} from "../basemap.service";
import {LayerService} from "../../../../modules/layer/layer.service";

@Component({
  selector: 'app-basemap-selector',
  templateUrl: './basemap-selector.component.html',
  styleUrls: ['./basemap-selector.component.scss']
})
export class BasemapSelectorComponent {
  public baseMap = '';

  constructor(
    public permissionService: PermissionService,
    public dataModeService: DataModeService,
    private baseMapService: BasemapService,
    private layerService: LayerService
  ) {
    this.baseMap = './assets/basemap/Satellite.png';
  }

  // Map selector (thematic or satellite)
  ChangeBasemap() {
    this.baseMap = './assets/basemap/' + (this.baseMap.includes('OSM') ? 'Satellite' : 'OSM') + '.png';
    this.baseMapService.isSatelliteImage = !this.baseMapService.isSatelliteImage;
    this.baseMapService.isSatelliteImage ? this.layerService.SetSatellite() : this.layerService.SetOSM();
  }

  // Get image for preview tile
  ImageName(): string {
    return this.baseMap.includes('OSM') ? 'OSM' : 'Satellite'
  }
}

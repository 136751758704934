import {AfterViewChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import localDe from '@angular/common/locales/de';
import localEn from '@angular/common/locales/en';
import localFr from '@angular/common/locales/fr';
import localNl from '@angular/common/locales/nl';
import localEs from '@angular/common/locales/es';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {BasemapService} from './core/modules/basemap/basemap.service';
import {EPSGType} from './shared/contract/Enum/EPSG';
import {Coordinate, ObjectCoordinate} from './shared/contract/Address/Coordinates';
import {OptionService} from './modules/option/option.service';
import {DataModeService} from './core/modules/menu/data-mode.service';
import {PermissionService} from './core/services/permission.service';
import {InterfaceStatus} from './shared/contract/Enum/Status';
import {ModuleEnum} from './shared/contract/Enum/ModuleEnum';
import {InitService} from './core/services/init.service';
import {MapViewService} from './shared/services/map-view.service';
import {QueryParamService} from "./core/services/query-param.service";
import {LoadingService} from "./core/modules/loading/loading.service";
import {ResidentialRealEstateDataService} from "./modules/residential-real-estate/residential-real-estate-data.service";
import {CommercialRealEstateDataService} from "./modules/commercial-real-estate/commercial-real-estate-data.service";
import {BuildingLandDataService} from "./modules/building-land/building-land-data.service";
import {ObjectInformationService} from "./modules/object-information/object-information.service";
import {Marker} from "./shared/contract/Enum/Marker";
import {Stroke} from "ol/style";
import {MarkerConfig} from "./shared/contract/Map/MarkerConfig";
import {MapLayer} from "./shared/contract/Enum/MapLayer";
import {MarkerService} from "./shared/services/marker.service";
import {CountryRightRequest} from "./shared/contract/UserInfo/CountryRight";
import {NotificationService} from "./core/modules/notification/notification.service";
import {NotificationType} from "./shared/contract/Enum/NotificationType";
import {TechnicalProtocolService} from "./core/services/technical-protocol.service";
import {firstValueFrom} from "rxjs";
import {ReceptionService} from "./core/modules/reception/reception.service";
import {Title} from "@angular/platform-browser";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewChecked {
    public title = 'STmate';

    constructor(
        public queryParamService: QueryParamService,
        public loadingService: LoadingService,
        private baseMapService: BasemapService,
        private optionService: OptionService,
        private router: Router,
        private permissionService: PermissionService,
        private dataModeService: DataModeService,
        private translationService: TranslateService,
        private initService: InitService,
        private activatedRoute: ActivatedRoute,
        private mapViewService: MapViewService,
        private cd: ChangeDetectorRef,
        private residentialRealEstateService: ResidentialRealEstateDataService,
        private commercialRealEstateDataService: CommercialRealEstateDataService,
        private buildingLandDataService: BuildingLandDataService,
        private objectInformationService: ObjectInformationService,
        private markerService: MarkerService,
        private notificationService: NotificationService,
        private technicalProtocolService: TechnicalProtocolService,
        private receptionService: ReceptionService
    ) {
        // Set default language
        registerLocaleData(localDe, 'de');
        this.translationService.setDefaultLang('de');
        this.optionService.locale = 'de';
    }

    ngAfterViewChecked () {
        this.cd.detectChanges();
    }

    async ngOnInit() {
        this.baseMapService.isSatelliteImage = false;
        this.initService.InitData = await firstValueFrom(this.initService.GetInitData());
        this.technicalProtocolService.InitializeTechnicalProtocol();
        this.dataModeService.GetAllowedModes();
        // this.optionService.Timer(this.initService.InitData.SessionTimeout);

        // Get the last selected language from user
        if (this.optionService.locale !== this.initService.InitData.Language) {
            registerLocaleData((
                this.initService.InitData.Language === 'de' ? localDe :
                    this.initService.InitData.Language === 'en' ? localEn :
                        this.initService.InitData.Language === 'fr' ? localFr :
                            this.initService.InitData.Language === 'nl' ? localNl :
                                this.initService.InitData.Language === 'es' ? localEs : localDe
            ), this.initService.InitData.Language)
            this.translationService.setDefaultLang(this.initService.InitData.Language);
            this.optionService.locale = this.initService.InitData.Language;
        }

        this.notificationService.Open(NotificationType.Info, this.translationService.instant('Exception.None.WelcomeMessage.Welcome'), this.translationService.instant('Exception.None.WelcomeMessage.WelcomeMessage') + this.initService.InitData.DisplayUserName + '!', 2)

        // Read the query parameter from url
        this.activatedRoute.queryParams.subscribe(params => {
            const lowerParams: Params = {};
            for (const key in params) {
                lowerParams[key.toLowerCase()] = params[key];
            }
            this.queryParamService.ReadQueryParameter(lowerParams) ;
        })

        // Start navigation with the given query parameter
        await this.InitialNavigation();
        this.permissionService.HasShop = await firstValueFrom(this.receptionService.HasShopRight());
        document.addEventListener('click', () => { this.optionService.Reset() });
    }

    async InitialNavigation() {
        this.loadingService.isLoading.next(true);
        // Get the initial start module by object subtype and country
        this.queryParamService.queryParameter.Module = (await firstValueFrom(this.initService.GetStartModule(this.queryParamService.queryParameter.ObjectInformation.ObjectSubType, this.queryParamService.queryParameter.Address.Coordinate.Country))).StartModule

        // Set coordinates and view
        this.baseMapService.CurrentSelectedMarkerCoordinate = new Coordinate([this.queryParamService.queryParameter.Address.Coordinate.Longitude, this.queryParamService.queryParameter.Address.Coordinate.Latitude], EPSGType.LonLat);
        this.mapViewService.SetViewByCoordinates(this.baseMapService.CurrentSelectedMarkerCoordinate.Get(EPSGType.OSM), 13);

        if ((this.queryParamService.queryParameter.Module === ModuleEnum.APS || this.queryParamService.queryParameter?.Module === ModuleEnum.BPS || this.queryParamService.queryParameter?.Module === ModuleEnum.CommercialAVM) && this.queryParamService.queryParameter.HasCoordinates) {
            const objectCoordinates = {Country: null, Latitude: this.queryParamService.queryParameter.Address.Coordinate.Latitude, Longitude: this.queryParamService.queryParameter.Address.Coordinate.Longitude} as ObjectCoordinate
            // Check if user has right for the country
            const countryRightResponse = await firstValueFrom(this.permissionService.GetCountryRight({Coordinate: objectCoordinates, ModuleId: this.queryParamService.queryParameter?.Module} as CountryRightRequest));
            this.baseMapService.Country.next(countryRightResponse.Country);
            this.initService.IsRequest = true;

            if (countryRightResponse.InterfaceStatus === InterfaceStatus.OK) {
                // Set marker by coordinates
                this.markerService.SetMarker([{
                    Name: ['STmate-Icon'],
                    Point: this.baseMapService.CurrentSelectedMarkerCoordinate,
                    Source: Marker.Object,
                    Scale: 2.5,
                    Baseline: 'bottom',
                    Alignment: 'center',
                    Color: '#a2c510',
                    Stroke: new Stroke({ color: '#32547b', width: 2 }),
                    Radius: null
                } as MarkerConfig], MapLayer.Marker, 7);

                // Initialize residential real estate
                if (this.queryParamService.queryParameter.Module === ModuleEnum.APS) {
                    this.dataModeService.DataMode = this.dataModeService.AllowedModes.find(e => e.Module === 'ResidentialRealEstate');
                    this.residentialRealEstateService.ResidentialRealEstateRequestData.Address = this.queryParamService.queryParameter.Address;
                    this.objectInformationService.createNewObject = true;
                    this.queryParamService.redirected = true;
                    this.objectInformationService.editObjectInformation = true;
                    this.objectInformationService.address = this.queryParamService.queryParameter.Address;
                    this.objectInformationService.objectInformation = this.queryParamService.queryParameter.ObjectInformation;

                    await this.router.navigate(['ResidentialRealEstate', 'ObjectInformation', 'Edit'], {
                        queryParams: {
                            Latitude: this.baseMapService.CurrentSelectedMarkerCoordinate.Get(EPSGType.LonLat).Y,
                            Longitude: this.baseMapService.CurrentSelectedMarkerCoordinate.Get(EPSGType.LonLat).X,
                            Country: countryRightResponse.Country
                        }
                    });
                } else if (this.queryParamService.queryParameter.Module === ModuleEnum.CommercialAVM) {
                    // Initialize commercial real estate
                    this.dataModeService.DataMode = this.dataModeService.AllowedModes.find(e => e.Module === 'CommercialRealEstate');
                    this.commercialRealEstateDataService.CommercialRealEstateRequestData.Address = this.queryParamService.queryParameter.Address;
                    this.commercialRealEstateDataService.CommercialRealEstateRequestData.ObjectInformation = this.queryParamService.queryParameter.ObjectInformation;

                    await this.router.navigate(['CommercialRealEstate', 'DataRequest'],{
                        queryParams: {
                            Latitude: this.commercialRealEstateDataService.CommercialRealEstateRequestData.Address.Coordinate.Latitude,
                            Longitude: this.commercialRealEstateDataService.CommercialRealEstateRequestData.Address.Coordinate.Longitude,
                            Country: this.commercialRealEstateDataService.CommercialRealEstateRequestData.Address.Coordinate.Country
                        }
                    });
                } else if (this.queryParamService.queryParameter.Module === ModuleEnum.BPS) {
                    // Initialize building land
                    this.buildingLandDataService.BuildingLandRequestData.Country = countryRightResponse.Country;
                    this.buildingLandDataService.BuildingLandRequestData.Latitude = this.queryParamService.queryParameter.Address.Coordinate.Latitude;
                    this.buildingLandDataService.BuildingLandRequestData.Longitude = this.queryParamService.queryParameter.Address.Coordinate.Longitude;
                    this.buildingLandDataService.BuildingLandRequestData.PlotSize = this.queryParamService.queryParameter.ObjectInformation.PlotSize;
                    this.dataModeService.DataMode = this.dataModeService.AllowedModes.find(e => e.Module === 'BuildingLand');
                    await this.router.navigate(['BuildingLand', 'AreaRequest'], {
                        queryParams: {
                            Latitude: this.baseMapService.CurrentSelectedMarkerCoordinate.Get(EPSGType.LonLat).Y,
                            Longitude: this.baseMapService.CurrentSelectedMarkerCoordinate.Get(EPSGType.LonLat).X,
                            Country: countryRightResponse.Country,
                            Area: null
                        }
                    })
                }
            } else {
                if (countryRightResponse.InterfaceStatus === InterfaceStatus.ActivePackagePromoNotInCountry) {
                    this.notificationService.Open(NotificationType.Failure, this.translationService.instant('Exception.Header.Failure'), this.translationService.instant('Exception.Failure.Status.' + countryRightResponse.InterfaceStatus, { Country: this.translationService.instant('Properties.Country.' + countryRightResponse.Country) }), 3)
                } else if (this.queryParamService.queryParameter.Module !== null) {
                    this.notificationService.Open(NotificationType.Failure, this.translationService.instant('Exception.Header.Failure'), this.translationService.instant('Exception.Failure.Status.' + countryRightResponse.InterfaceStatus), 3)
                }
                this.initService.IsRequest = false;
                this.loadingService.isLoading.next(false);
            }
        } else {
            this.router.navigateByUrl('/').then();
            this.loadingService.isLoading.next(false);
        }
    }

    // Check if the user is allowed to use the satellite image
    BaseMapRight(): boolean {
        return (this.initService.InitData.ModuleRight.find(e => e.Name.includes('Satellite')) !== null && this.initService.InitData.ModuleRight.find(e => e.Name.includes('Satellite')) !== undefined)
    }
}

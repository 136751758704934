<div id="tooltip" [ngClass]="'tooltip'">
    <div [ngClass]="'tooltip-header'">
        <div [ngClass]="'title'">
            <div [ngClass]="'main-header'">{{ SetTitleText() }}</div>
            <div [ngClass]="'sub-header'">{{ SetTitleText(true) }}</div>
        </div>
        <fa-icon (click)="RemoveObject($event)" class="fa-lg" [icon]="fontAwesomeService.LightIcons.TimesCircle"></fa-icon>
    </div>
    <div [ngClass]="'tooltip-body'">
        <div [ngClass]="'table-list'" *ngIf="popupService.ObjectInformationList.length > 0">
            <ng-container *ngFor="let item of popupService.ObjectInformationList">
                <div>
                    <div>{{ SetPropertyText(item) }}</div>
                    <div>{{ SetProperty(item) }}</div>
                </div>
            </ng-container>
        </div>
        <div *ngIf="popupService.SelectedPortfolioLocation?.PortfolioLocationObjects?.length > 1 && popupService.Mode === 'Portfolio'">
            <mat-form-field appearance="outline">
                <mat-label>{{ 'Module.ObjectInformation.ObjectNumber' | translate }}</mat-label>
                <mat-select [ngModel]="popupService.SelectedPortfolioLocation?.PortfolioLocationObjects" (ngModelChange)="SelectPortfolioObject($event, $event)">
                    <mat-option *ngFor="let objectItem of popupService.SelectedObjectDetails?.PortfolioLocationsDetailObjects" [value]="objectItem">
                        {{ objectItem.CustomerId === null ? ('Properties.BooleanType.0' | translate) : objectItem.CustomerId }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="popupService.SelectedCompareObjects?.length > 0 && popupService.Mode !== 'Portfolio'">
            <mat-form-field appearance="outline">
                <mat-label>{{ 'Table.Header.Date.TransactionDate' | translate }}</mat-label>
                <mat-select [ngModel]="popupService.SelectedObject" (ngModelChange)="SelectCompareObject($event, $event)">
                    <mat-option *ngFor="let objectItem of popupService.SelectedCompareObjects" [value]="objectItem">
                        {{ SetCompareObjectSelectionText(objectItem) }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div [ngClass]="'grid-min-full grid-column-gap'" *ngIf="popupService.Mode === 'Layer'">
            <ng-container *ngFor="let mapItem of mapFeatureInfoService.mapFeatureInfo.features">
                <div [ngClass]="'colorbox'" [style.background-color]="GetLegendForMapFeature(true, mapItem.properties.Value) "></div>
                <div>{{ GetLegendForMapFeature(false, mapItem.properties.Value) }}</div>
            </ng-container>
        </div>
    </div>
    <div [ngClass]="'tooltip-footer'">
        <button *ngIf="popupService.allowPopupNavigation" mat-button mat-raised-button color="accent" (click)="Navigate($event)">{{ SetButtonText() }}</button>
    </div>
</div>

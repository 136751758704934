<div map-container oncontextmenu="return false">
  <div report-container *ngIf="spatialSelectionService.screenSize.showReportView" (window:resize)="spatialSelectionService.ActivateReportView()">
    <div class="padding-top" [style.height.px]="spatialSelectionService.screenSize.topEdge"></div>
    <div class="padding-left" [style.width.px]="spatialSelectionService.screenSize.leftEdge" [style.top.px]="spatialSelectionService.screenSize.topEdge" [style.bottom.px]="spatialSelectionService.screenSize.topEdge"></div>
    <div class="padding-right" [style.width.px]="spatialSelectionService.screenSize.leftEdge + spatialSelectionService.screenSize.sidebarWidth" [style.top.px]="spatialSelectionService.screenSize.topEdge" [style.bottom.px]="spatialSelectionService.screenSize.topEdge"></div>
    <div class="padding-bottom" [style.height.px]="spatialSelectionService.screenSize.topEdge"></div>
  </div>
  <div id="map" #map (mousemove)="OnMouseMove($event)" (contextmenu)="OnRightClick($event)" (dblclick)="OnDoubleClick($event)" (click)="OnClick($event)"></div>
  <app-popup></app-popup>
</div>

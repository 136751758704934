<div [ngClass]="'notification-container'" *ngIf="notificationService.showNotification">
    <div [ngClass]="'notification'" cdkDrag cdkDragBoundary=".notification-container">
        <div [ngClass]="'notification-header'" cdkDragHandle>
            <div [ngClass]="'popup-icon'" [style.background-color]="GetIconBackgroundColor()">
                <fa-icon [icon]="GetIcon()"></fa-icon>
            </div>
            <div>
                <h3>{{ notificationService.notification.Title | translate}}</h3>
            </div>
            <div [ngClass]="'close-icon'">
                <fa-icon [icon]="fontAwesomeService.LightIcons.TimesCircle" class="fa-lg" (click)="notificationService.HideNotification()"></fa-icon>
            </div>
        </div>
        <div [ngClass]="'notification-body'">
            {{ notificationService.notification.Text | translate}}
        </div>
        <div [ngClass]="'notification-footer'">
            <mat-progress-bar [mode]="'buffer'" [value]="CalculateProgressBar()"></mat-progress-bar>
        </div>
    </div>
</div>
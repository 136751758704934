import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PermissionService } from '../services/permission.service';
import { CacheService } from '../../modules/cache/cache.service';
import { DataModeService } from '../modules/menu/data-mode.service';
import { MinimizationService } from '../modules/menu/minimization.service';
import {LoadingService} from "../modules/loading/loading.service";
import {NotificationService} from "../modules/notification/notification.service";
import {NotificationType} from "../../shared/contract/Enum/NotificationType";

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard  {
  private moduleName: string;

  constructor(
    private permissionService: PermissionService,
    private router: Router,
    private cacheService: CacheService,
    private translationService: TranslateService,
    private dataModeService: DataModeService,
    private minimizationService: MinimizationService,
    private loadingService: LoadingService,
    private notificationService: NotificationService
  ) {}

  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.router.navigated || this.dataModeService.DataMode !== null) {
      // Get module name
      this.moduleName = this.dataModeService.DataMode?.Module;

      // Report quota
      return this.permissionService.GetQuota().pipe(map(quota => {
        // Allow navigation if user have quota, module was minimized or request was cached
        if (quota > 0) {
          return true;
        } else if (this.minimizationService.wasMinimized !== undefined) {
          if (this.minimizationService.wasMinimized?.Module === this.moduleName) {
            return true;
          }
        } else if (this.cacheService.isCache) {
          return true;
        } else {
          // If no permission enter the paywall and give a notification
          if (this.moduleName === 'AddressSearch') {
            this.notificationService.Open(NotificationType.Info, this.translationService.instant('Exception.Header.Info'), this.translationService.instant('Exception.Info.AddressSearch'), 3)
            this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
                this.router.navigateByUrl('/Reception')
            )
          } else if (this.dataModeService.DataMode?.Module === 'LineMeasurement') {
            return true;
          }  else {
            this.notificationService.Open(NotificationType.Info, this.translationService.instant('Exception.Header.Info'), this.translationService.instant('Exception.Info.Quota'), 3)
            setTimeout(() => {
              this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
                  this.router.navigateByUrl('/Reception')
              )
            }, 2000);
          }
          return false;
        }
      }));
    } else {
      this.loadingService.isLoading.next(false);
      return false;
    }
  }
}

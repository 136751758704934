<app-basemap *ngIf="queryParamService.queryParameter.Address.Coordinate.Longitude !== null && queryParamService.queryParameter.Address.Coordinate.Latitude !== null" class="font-awesome"></app-basemap>
<app-task-bar></app-task-bar>
<app-task-minimize></app-task-minimize>
<app-notification></app-notification>
<app-legend></app-legend>

<div [ngClass]="'tile-container'">
    <app-basemap-selector *ngIf="BaseMapRight()"></app-basemap-selector>
    <app-legend-selector></app-legend-selector>
</div>

<router-outlet *ngIf="queryParamService.queryParameter.Address.Coordinate.Longitude !== null && queryParamService.queryParameter.Address.Coordinate.Latitude !== null"></router-outlet>

<div *ngIf="loadingService.isLoading | async" class="relative">
    <app-loading></app-loading>
</div>

import { Component } from '@angular/core';
import {FontAwesomeService} from "../../../../font-awesome/font-awesome.service";
import {NotificationService} from "../notification.service";
import {NotificationType} from "../../../../shared/contract/Enum/NotificationType";
import {IconDefinition} from "@fortawesome/pro-light-svg-icons";

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})

export class NotificationComponent {
    constructor(
        public fontAwesomeService: FontAwesomeService,
        public notificationService: NotificationService
    ) { }

    CalculateProgressBar(): number {
        return (this.notificationService.remainingTime / this.notificationService.notification.Duration * 100)
    }

    GetIcon(): IconDefinition {
        switch (this.notificationService.notification.Type) {
            case NotificationType.None:
                return this.fontAwesomeService.LightIcons.Info;
            case NotificationType.Info:
                return this.fontAwesomeService.LightIcons.Info;
            case NotificationType.Success:
                return this.fontAwesomeService.LightIcons.Check;
            case NotificationType.Failure:
                return this.fontAwesomeService.LightIcons.Times;
        }
    }

    GetIconBackgroundColor(): string {
        switch (this.notificationService.notification.Type) {
            case NotificationType.None:
                return '#32547b';
            case NotificationType.Info:
                return '#32547b';
            case NotificationType.Success:
                return '#a2c510';
            case NotificationType.Failure:
                return '#ee3b3b';
        }
    }
}

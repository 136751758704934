import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PopupComponent} from "./popup/popup.component";
import {SharedModule} from "../../../shared/shared.module";
import {FontAwesomeIconModule} from "../../../font-awesome/font-awesome-icon.module";
import {TranslateModule} from "@ngx-translate/core";
import {MaterialModule} from "../../../material/material.module";
import {FormsModule} from "@angular/forms";

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        SharedModule,
        FontAwesomeIconModule,
        TranslateModule,
        MaterialModule
    ],
  declarations: [
      PopupComponent
  ],
  exports: [
      PopupComponent
  ]
})
export class PopupModule { }

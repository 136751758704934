import { NgModule } from '@angular/core';
import {CommonModule, DecimalPipe, NgOptimizedImage} from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../../../material/material.module';
import { BasemapComponent } from './basemap/basemap.component';
import { ConfirmationModule } from '../confirmation/confirmation.module';
import { PopupModule } from "../popup/popup.module";
import {SharedModule} from "../../../shared/shared.module";
import {BasemapSelectorComponent} from "./basemap-selector/basemap-selector.component";

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule,
        ConfirmationModule,
        PopupModule,
        SharedModule,
        NgOptimizedImage
    ],
  providers: [DecimalPipe],
  exports: [ BasemapComponent, BasemapSelectorComponent ],
  declarations: [ BasemapComponent, BasemapSelectorComponent ]
})

export class BasemapModule { }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import {InitService} from "../services/init.service";

@Injectable({
    providedIn: 'root'
})
export class RedirectionService {
    private GetRedirectAutUrl = '/api/GetRedirectionUrlForAuthorizationCodeFlow';

    constructor(
        private http: HttpClient,
        private initService: InitService
    ) {}

    GetRedirectionUrl() {
        return this.http.get(this.initService.GetApiUrl(this.GetRedirectAutUrl), {
            responseType: 'text',
            withCredentials: false
        });
    }
}
